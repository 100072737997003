@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
}

.dropdown-content a {
  display: block;
}


.dropdown:hover .dropdown-content {
  display: block;
}
